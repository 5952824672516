import React from 'react';
import "./Footer.css";
import { faFacebookF , faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
    const facebookUrl = 'https://www.facebook.com/bus.macedonia';
    const instagramUrl = 'https://www.instagram.com/bus.mk/';
    const twitterUrl ='https://twitter.com/bus_macedonia';
    const linkedinUrl ='https://www.linkedin.com/company/bus.mk';

    return (
        <div className='footer'>
            <div className="container">

                    <div className="socials-copyright">
                        <p className="copyright text-center">Copyright © 2023 BusMk. All rights reserved.</p>
                    </div>
                    <div className="socials" >
                        <a href={facebookUrl} target="_blank" rel="noopener noreferrer" className="icons">
                        <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href={instagramUrl} target="_blank" rel="noopener noreferrer"  className="icons">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="icons">
                            <FontAwesomeIcon icon={faTwitter}/>
                        </a>
                        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className="icons">
                            <FontAwesomeIcon icon={faLinkedin}/>
                        </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
